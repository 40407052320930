import { Routes, Route, BrowserRouter as Router } from "react-router-dom";

import TicketIndex from "./pages/tickets/TicketIndex";
import TicketCreate from "./pages/tickets/TicketCreate";
import TicketEdit from "./pages/tickets/TicketEdit";
import ValidateState from "./pages/tickets/ValidateState";

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";

function App() {
  
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<TicketIndex />} />
        <Route path="/authorized/:token" element={ <ValidateState /> }/>
        <Route path="/ticket-create" element={<TicketCreate />} />
        <Route path="/ticket-create-:valueId" element={<TicketCreate />} />
        <Route path="/call-:ticketId" element={<TicketEdit />} />
      </Routes>
      <Footer />
    </>
  );
}

// function MissingRoute() {
//   return <Navigate to={{pathname: '/ticket'}}/>
// }

export default App;
