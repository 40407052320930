import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import * as MaterialIcon from "@mui/icons-material";
import * as MaterialUI from "@mui/material";
import _ from 'lodash';
import { TicketDataService } from "../../services/ticket-service";
import { useTranslation } from "react-i18next";

const TicketCreate = () => {
  const { t } = useTranslation();
  const MAX_COUNT = 5;
  const handleUploadFile = files => {
    const uploaded = [...uploadFiles];
    let limitExceeded = false;
    files.forEach( async (file) => {
      if(uploaded.findIndex((f) => f.some === file.name) === -1){
        var result = uploadFiles.map((e) => e.name === file.name).find((e) => { return e === true});

        if(!result) uploaded.push(file);
        
        if(uploaded.length === MAX_COUNT) setFileLimit(true);
        if(uploaded.length > MAX_COUNT){
          alert(t('error_length_upload_1') + MAX_COUNT + t('error_length_upload_2'));
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    })

    if(!limitExceeded)setUploadFiles(uploaded);
  }

  const handleFileEvent = (file) => {
    const selectedFile = Array.prototype.slice.call(file.target.files);
    handleUploadFile(selectedFile);
  };

  const handleRemoveFile = (file) => {
    const uploaded = [...uploadFiles];
    //const index = uploaded.findIndex((f) => f.name === file.name);

    uploaded.splice(file, 1);
    setUploadFiles(uploaded);
    setFileLimit(false);
  };

  const initialValue = { type: undefined, title: '', contents: '' };

  const optionList = [];
  const [title, setTitle] = useState("");
  const [telContact, setTelContact] = useState("");
  const [contents, setContents] = useState("");
  const [uploadFiles, setUploadFiles] = useState([]);
  const [fileLimit, setFileLimit] = useState(false);
  const [status, setStatus] = useState(false);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [formValues, setFormValues] = useState(initialValue);
  const [formError, setFormError] = useState({});
  const [defaultValue, setDefaultValue] = useState('');
  const { valueId } = useParams();
  const navigate = useNavigate();

  const optionData = async () => {
    try
    {
      const res = await TicketDataService.optionTicketData();
      if(res.data.status === true){
        setLoading(false);
        setOptions(res.data.data);
      }else{
        navigate("/");
      }
    }
    catch(err){}
  }

  //Options Data
  if(options.length > 0 || options !== options) {
    options.forEach( async (element) => {
      const optiondata = {value: element.optionValue, label: element.optionDescrip}
      await optionList.push(optiondata);
    });
  }

  useEffect( () => {
    //handleValue();
    optionData();
    (!defaultValue && valueId !== undefined) && setDefaultValue(valueId);
    //valueId !== undefined && setSelectedType(optionList.find(option => option.value === parseInt(valueId)));
    if(Object.keys(formValues).length > 0){
      setTitle(formValues.title);
      setContents(formValues.contents);
    }
  }, [formError]);

  const handleChange = (e) => {
    if(e.target !== undefined){
      const { name, value } = e.target;
      setFormValues({ ...formValues, [name]: value });
    }else{
      const name = 'type';
      const value = e;
      setFormValues({ ...formValues, [name]: value.label });
    }
  }

  const validate = (values) => {
    const error = {};

    if(!values.title){
      error.title = t('error_input_title');
    }
    if(!values.contents){
      error.contents = t('error_input_content');
    }
    if(!defaultValue && !values.type){
      error.type = t('error_input_type');
    }

    return error;
  }

  const postData = async (e) => {
    e.preventDefault();
    setFormError(validate(formValues));

    if(Object.keys(formError).length === 0){
      const formData = new FormData();

    _.forEach(uploadFiles, (file) => {
      formData.append('files', file);
    });
    formData.append("title", title);
    formData.append("type", defaultValue);
    formData.append("telContact", telContact);
    formData.append("contents", contents);

    try {
      await TicketDataService.postTickcreat(formData).then(function(res) {
        if(res.data.status !==  false){
          setStatus(true);
        }else{
          console.log(res.data.message);
        }
      }).catch(function(err) {
        console.log(err);
      });
    } catch (err) {
      console.log(err.message);
    }
    }
  };

  if(status){
    return navigate("/");
  }
  
  return (
    <>
    {loading ? (
      <>
      </>
    ) : (
      <div className="container" style={{ paddingTop: "105px", paddingBottom: "100px" }}>
        <div className="text-center pb-3">
          <MaterialIcon.QuestionAnswer
            className="mb-2"
            sx={{ fontSize: "3.5rem" }}
          />
          <h4 style={{ fontWeight: "bold" }}>{t('create_header')}</h4>
          <h6>{t('create_subheader')}</h6>
        </div>
        {/* <pre>{JSON.stringify(formValues, undefined, 2)}</pre> */}
        <div className="container" style={{ alignContent: "center", paddingInline: "1rem" }}>

          {/* Options Area */}
          <div className="form-group pb-2">
          <label
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                paddingBottom: ".25rem",
              }}
            >
              {t('create_title_type')}
              <span style={{ color: "red" }}>
                <strong> *</strong>
              </span>
            </label>
            <select
              className="form-control"
              name="type"
              onChange={(e) => {
                handleChange(e);
                setDefaultValue(e.target.value);
              }}
              value={defaultValue}
            >
              <option value="">{t('create_placeh_type')}</option>
              {optionList.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            {formError.type && (<p style={{fontSize: "16px", marginTop: "5px", color: "#F44336"}}><MaterialIcon.Error sx={{fontSize: "18px", color: "#F44336"}} />{formError.type}</p>)}
          </div>

          {/* Title Area */}
          <div className="form-group pb-2">
            <label
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                paddingBottom: ".25rem",
              }}
            >
              {t('create_title_title')}
              <span style={{ color: "red" }}>
                <strong> *</strong>
              </span>
            </label>

            <input
              type="text"
              name="title"
              id="title"
              className="block-input mb-0"
              style={{ width: "100%" }}
              placeholder={t('create_placeh_title')}
              value={formValues.title}
              onChange={(e) => {
                setTitle(e.target.value);
                handleChange(e);
              }}
              required
              maxLength={75}
            />
            {formError.title && (<p style={{fontSize: "16px", marginTop: "5px", color: "#F44336"}}><MaterialIcon.Error sx={{fontSize: "18px", color: "#F44336"}} />{formError.title}</p>)}
          </div>

          {/* Contact Area */}
          <div className="form-group pb-2">
            <label
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                paddingBottom: ".25rem",
              }}
            >
              {t('create_title_tel')}
            </label>

            <input
              name="telContact"
              id="telContact"
              className="block-input mb-0"
              style={{ width: "100%" }}
              placeholder={t('create_placeh_tel')}
              onChange={(e) => setTelContact(e.target.value)}
            />
          </div>

          {/* Content Area */}
          <div className="form-group mb-0 pb-2">
            <label
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                paddingBottom: ".25rem",
              }}
            >{t('create_title_content')}<span style={{ color: "red" }}><strong> *</strong></span>
            </label>
            <textarea
              name="contents"
              id="contents"
              onChange={(e) => {
                setContents(e.target.value);
                handleChange(e);
              }}
              placeholder={t('create_plach_content')}
              rows={3}
              value={formValues.contents}
              className="w-100 block-input mb-0"
              style={{ minHeight: 150 }}
              required
            />
            {formError.contents && (<p style={{fontSize: "16px", marginTop: "5px", color: "#F44336"}}><MaterialIcon.Error sx={{fontSize: "18px", color: "#F44336"}} />{formError.contents}</p>)}
          </div>

          <div className="row">
            <label
              style={{
                fontSize: "16px",
                fontWeight: "bold",
                paddingBottom: ".25rem",
              }}
              htmlFor="ticketUpload"
            >
              {t('create_title_upload')}
            </label>
            <label
              style={{
                fontSize: "16px",
                paddingBottom: ".25rem",
              }}
              >
              {t('create_plach_upload_1')} {uploadFiles.length} / {MAX_COUNT} {t('create_plach_upload_2')}
            </label>
          </div>

          {/* Upload Area */}
          <label className="w-100 ticketUpload">
              <MaterialUI.Button
                className="mt-2"
                fullWidth
                variant="outlined"
                component="label"
                size="small"
                disabled={fileLimit}
                startIcon={<MaterialIcon.FileUpload />}
              >
                {t('create_upload_btn')}
                <input className="uploadFile"
                  id="fileUploads" 
                  type={"file"} hidden 
                  accept="application/pdf,image/*"
                  onChange={handleFileEvent}
                  disabled={fileLimit}
                  multiple
                   />
              </MaterialUI.Button>
          </label>
          <div className="d-flex flex-wrap w-100">
                {uploadFiles.map((file, index) => (
                  <div className="col-12 d-flex me-2" key={index}>
                    {
                      <MaterialUI.Button
                      className="mt-2"
                      fullWidth
                      color="success"
                      variant="outlined"
                      href={file.url}
                      target="_blank"
                      textTransform= 'none'
                      size="small"
                      justifyContent="between"
                      accept="application/pdf,image/*"
                      endIcon={
                        <MaterialUI.Tooltip title={t('create_upload_del_btn_tip')}>
                          <MaterialUI.IconButton>
                            <MaterialIcon.Close sx={{fontSize: '20px'}} onClick={() => handleRemoveFile(index)}/>
                          </MaterialUI.IconButton>
                        </MaterialUI.Tooltip>
                      }
                      startIcon={(() => {
                        switch (file.type) {
                          case "image/png":
                            return <MaterialIcon.Image />;
                          case "image/jpeg":
                            return <MaterialIcon.Image />;
                          case "text/plain":
                            return <MaterialIcon.NoteAlt />;
                          case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                            return <MaterialIcon.Article />;
                          case "application/pdf":
                            return <MaterialIcon.PictureAsPdf />;
                          default:
                            return <MaterialIcon.FileOpen />;
                        }
                      })()}
                    >
                      <MaterialUI.Typography
                        variant="body2"
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          textAlign: "center"
                        }}
                      >
                        {file.name.split(".")[0]}
                      </MaterialUI.Typography>
                    </MaterialUI.Button>
                    }
                  </div>
                ))}
          </div>


          <hr />
          <div className="form-group">
            <input
              onClick={postData}
              type="button"
              className="btn btn-block w-100 btn-success"
              value={t('create_ticket_btn')}
            />
          </div>
        </div>
      </div>
      ) 
    }
      
    </>
  );
};

export default TicketCreate;
