import http from "../http-common";

const authenState = (token) => {
    return http.get(`ticket/authorized?token=${token}`);
}
const getTickall = (data) => {
    return http.post(`ticket`, data);
};
const getTickcout = () => {
    return http.get(`ticket/issuecount`);
};
const getTickdetail = (ticketId) => {
    return http.get(`ticket/callticket?ticketId=${ticketId}`);
}
const postTickcreat = (data) => {
    return http.post(`ticket/createnew`, data);
};
const postTickupdat = (ticketId, data) => {
    return http.post(`ticket/putticket?ticketId=${ticketId}`, data);
};
const postTickfeed = data => {
    return http.post("/ticket/feedback", data);
}
const postTickcance = (ticketId) => {
    return http.post(`ticket/disable?ticketId=${ticketId}`);
};

const optionTicketData = () => {
    return http.get(`/ticket/option`, {withCredentials: true});
}

const viewUploadFile = (filename) => {
    return http+(`uploads/${filename}`);
}


export const TicketDataService = {
    getTickall,
    getTickcout,
    postTickcreat,
    postTickupdat,
    postTickfeed,
    getTickdetail,
    postTickcance,
    viewUploadFile,
    optionTicketData,
    authenState
};
