import * as React from "react";
import * as MaterialUI from "@mui/material";

export const TicketDrawer = (props) => {
  const { children, open, onClose } = props;

  const [openState, setOpenState] = React.useState(open);
  const [anchor] = React.useState("right");

  React.useEffect(() => {
    setOpenState(open);
  } , [open]);

  return (
    <React.Fragment key={anchor}>
      <MaterialUI.Drawer 
        width={300}
        open={openState}
        docked={true}
        PaperProps={{
          style: {
            boxShadow: "none",
            borderRight: "1px solid #e0e0e0",
            width: "300px",
          }
        }}
        onClose={onClose}
        anchor={anchor}
        >
        {children}
      </MaterialUI.Drawer>
    </React.Fragment>
  );
};
