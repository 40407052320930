import axios from "axios"
  axios.defaults.withCredentials = true;
  const header = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Credentials': true,
    'Authorization': document.cookie,
  };
  
export default axios.create({
    baseURL: "https://ticket.skyfrogth.com/api/", //ticket homepro
    //baseURL: "https://ticket.skyfrog.dev/api",
   // baseURL: "https://ticket.skyfrog.net/api",
    headers: header,
    timeout: 5000
});