import MaterialTable from "material-table";
import React, { useState, forwardRef, useEffect } from "react";
import { AddBox, ArrowDownward, Check, ChevronLeft, 
  ChevronRight, Clear, DeleteOutline, Edit, FilterList, 
  FirstPage, LastPage, Remove, SaveAlt, Search, ViewColumn } from "@mui/icons-material";
import { Link } from "react-router-dom";
import * as MaterialIcons from "@mui/icons-material";
import * as MaterialUI from "@mui/material"
import * as bootstrap from "react-bootstrap";
import { Tooltip, Chip, IconButton, Alert, Snackbar } from "@mui/material";
import Select from 'react-select';
import { _ } from 'lodash';
import { TicketDataService } from ".././services/ticket-service";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";

import "react-datepicker/dist/react-datepicker.css";

const Dtable = () => {

  const { t } = useTranslation();
  const optionsList = [];
  var limitSize = 5;
  const orderBydate = "CreateDate";
  const [id, setId] = useState("");
  const [title, setTitle] = useState("");
  const [cancleTicket, setCancleTicket] = useState(false);
  const [querys, setQuerys] = useState([]);

  const [startDates, setStartDates] = useState();
  const [endDates, setEndDates] = useState();
  const [statusQ, setStatusQ] = useState([]);
  const [key, setKey] = useState(false);

  const [queryLoad, setQueryLoad] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [cancelAlert, setCancelAlert] = useState(false);
  const [servetAlert, setServetAlert] = useState();
  const [cancelAlertMsg, setCancelAlertMsg] = useState("");

    const [limitSize2, setLimitSize2] = useState(5);

  const handleCancelAlert = () => {
    setCancelAlert((cancelAlert) => !cancelAlert)
  }

  useEffect(() => {
    ReloadTable();
  }, []);

  const ReloadTable = async (page, limit, search, status, startDate, endDate, orderBy, orderDir) => {

    //limit = limit === undefined || limit === null ? limitSize : limit;
    orderBy = orderBy === undefined || orderBy === null ? orderBydate : orderBy.toString();
    orderDir = orderDir === undefined || orderDir === null ? "desc" : orderDir;

      const data = { page: page, limit: limitSize2, q: search, status: status, startDate: startDate, endDate: endDate, orderBy: orderBy, orderDir: orderDir };

    try {
      await TicketDataService.getTickall(data).then(
        (response) => {
          if(response.data.status){
            setQuerys(response.data);
            setQueryLoad(false);
          }else{
            setQueryLoad(false);
            alert(response.data.message);
          }
        }
      ).catch((error) => {
        setQueryLoad(false);
        console.log(error);
      });
    }
    catch (err) {
      console.log(err);
    }
  }
  
  if(querys.statusOpt){
    querys.statusOpt.forEach((element) => {
      const optiondata = {value: element.optionValue, label: element.optionDescrip}
      optionsList.push(optiondata);
    });
  }

  const handleDisable = async (ticketId) => {
    try {
      await TicketDataService.postTickcance(ticketId)
        .then(function (res) {
          if (res.data.status !== false) {
            setCancleTicket(false);
            ReloadTable();
            setServetAlert("success");
            setCancelAlert(true);
            setCancelAlertMsg(t('success_cancel_status'));
          } else {
            setCancleTicket(false);
            ReloadTable();
            setServetAlert("error");
            setCancelAlert(true);
            setCancelAlertMsg(t('cannot_cancel_status'));
          }
        });
    } catch (err) {
      alert(err.message);
    }
  };

  const handleChangeStatus = (event) => {
    var statusQuery = [];
    setSelectedStatus(event);
    event.forEach((element) => {
      statusQuery.push(element.value);
    });
    setStatusQ(statusQuery);
  }

  const handleReset = () => {
    setStartDates();
    setEndDates();
    setStatusQ([]);
    setSelectedStatus([]);
  }

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const column = [
    {
      title: t('tb_ticketId'),
      field: "ticketId",
      render: (rowData) => {
        return (
          <>
            <div>{rowData.ticketId}</div>
            {rowData.customerRead === true ? (
              <Chip label={t('tb_newmessage')} color="primary" />
            ) : null}
          </>
        );
      },
    },
    { title: t('tb_title'), field: "title" },
    {
      title: t('tb_status'),
      field: "status",
      sorting: false,
      lookup: {
        1: (
          <Chip
            className="w-100"
            label={t('issue_create')}
            color="success"
            style={{backgroundColor: '#45ba48', borderColor: '#56BA5A'}}
          />
        ),
        2: (
          <Chip
            className="w-100"
            label={t('issue_pending')}
            color="warning"
          />
        ),
        3: (
          <Chip
            className="w-100"
            label={t('issue_monitoring')}
            color="warning"
          />
        ),
        4: (
          <Chip
            className="w-100"
            label={t('issue_closed')}
            color="secondary"
            style={{backgroundColor: '#eee0e5', color: '#5a5060'}}
          />
        ),
        5: (
          <Chip
            className="w-100"
            label={t('issue_cancel')}
            color="default"
          />
        ),
      },
      align: "center",
    },
    {
      title: t('tb_agent'), field: "agentRoleId", emptyValue: () => <em>-</em>,
    },
    {
      title: t('tb_createDate'),
      field: "createDate",
      dateSetting: { locale: "en-GB" },
      emptyValue: () => <em>-</em>,
      align: "center",
      type: "date",
      //defaultSort: "desc",
    },
    {
      title: t('tb_dueDate'),
      field: "dueDate",
      emptyValue: () => <em>-</em>,
      dateSetting: { locale: "en-GB" },
      align: "center",
      type: "date",
    },
    {
      title: t('tb_closedDate'),
      field: "closeDate",
      emptyValue: () => <em>-</em>,
      dateSetting: { locale: "en-GB" },
      align: "center",
      type: "date",
    },
    {
      title: "",
      field: "status",
      render: (rowData) => {
        return (
          <div>
            <Link to={`/call-` + rowData.ticketId}>
            <Tooltip title={t('tb_tootip_view')}>
              <IconButton
                color="success"
              >
                <MaterialIcons.StickyNote2 />
              </IconButton>
            </Tooltip>
            </Link>
            {rowData.status === 1 ? (
              <>
                <Tooltip title={t('tb_tootip_cancel')}>
                  <IconButton
                    onClick={function () {
                      setCancleTicket(true);
                      setId(rowData.ticketId);
                      setTitle(rowData.title);
                    }}
                  >
                    <MaterialIcons.Close />
                  </IconButton>
                </Tooltip>
              </>
            ) : null}
          </div>
        );
      },
      sorting: false,
    },
  ];

  const options = {
    responsive: "stacked",
    viewColumns: false,
    showTitle: false,
    paging: true,
    grouping: true,
    search: true,
    totalCount: querys.recordsTotal,
    page: 0,
    pageSize: 5,
    pageSizeOptions: [5, 10, 20, 50, 100],
    serverSide: true,
    selectableRows: false,
  }

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="card" style={{borderRadius: "0px"}}>
            {/* <div className="card-header">
              <h6 className="m-0" style={{fontSize: "19px", fontWeight: "500"}}>Filter Controller</h6>
            </div> */}
            <div className="card-body">
              <div className="row d-flex">
                <div className="col-md-4">
                  <div className="form-group">
                    <label style={{fontSize: "16px", marginTop: "10px", marginBottom: "2px", fontWeight: "bold"}}>{t('filter_label_status')}</label>
                    <Select
                        isMulti
                        name="statusOptions"
                        key={key}
                        placeholder={t('filter_status')}
                        options={optionsList}
                        defaultValue={selectedStatus}
                        onChange={ e => handleChangeStatus(e)}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        styles={{ menuPortal: base => ({ ...base, zIndex: 1 }) }}
                      />
                    </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label style={{fontSize: "16px", marginTop: "10px", marginBottom: "2px", fontWeight: "bold"}}>{t('filter_label_startDate')}</label>
                      <DatePicker
                        key={key}
                        isClearable
                        selected={startDates}
                        maxDate={!endDates ? new Date() : endDates}
                        onChange={(date) => {setStartDates(date)}}
                        dateFormat="dd/MM/yyyy"
                        placeholderText={t('filter_date')}
                        className="form-control"
                      />
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label style={{fontSize: "16px", marginTop: "10px", marginBottom: "2px", fontWeight: "bold"}}>{t('filter_label_endDate')}</label>
                    <DatePicker
                        //disabled={!startDates}
                        isClearable
                        selected={endDates}
                        minDate={startDates}
                        maxDate={new Date()}
                        onChange={(date) => {setEndDates(date)}}
                        dateFormat="dd/MM/yyyy"
                        placeholderText={t('filter_date')}
                        className="form-control"
                        style={{ width: "100%" }}
                      />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-4 col-lg-2 py-2">
                  <button
                    className="btn btn-primary w-100"
                    disabled={queryLoad}
                    onClick={() => {
                      setQueryLoad(true);
                      setTimeout(() => {
                          ReloadTable(0, limitSize2, '', statusQ, startDates, endDates);
                      }, 1000)
                    }}
                  >
                    {queryLoad ? (
                      <div>
                        <MaterialIcons.FindReplace style={{ marginRight: "5px" }} fontSize="small" />
                        {t('btn_wait_searching')}
                      </div>
                    ) : (
                      <div>
                        <MaterialIcons.Search style={{ marginRight: "8px" }} fontSize="small" />
                        {t('btn_searching')}
                      </div>
                    ) }
                  </button>
                </div>
                <div className="col-md-4 col-lg-2 py-2">
                  <button
                    disabled={!startDates && !endDates && statusQ.length === 0}
                    className="btn btn-outline-secondary w-100"
                    onClick={(e) => {
                      handleReset(e);
                      setTimeout(() => {
                        setKey(k => !k);
                          ReloadTable(0, limitSize2, null, [], null, null);
                      }, 1000)
                    }}
                  >
                    <MaterialIcons.Clear style={{ marginRight: "5px" }} fontSize="small" />
                    {t('btn_clear_filter')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ maxWidth: "100%" }}>
        <div className="mb-3">
          <Snackbar open={cancelAlert} autoHideDuration={6000} onClose={handleCancelAlert}>
            <Alert onClose={handleCancelAlert} severity={servetAlert} sx={{ width: '100%' }}>
              {cancelAlertMsg}
            </Alert>
          </Snackbar>
        </div>
        <MaterialTable
          style={{ paddingLeft: "10px", zIndex: "0" }}
          totalCount={querys.recordsTotal}
          page={querys.page}
          options={options}
          icons={tableIcons}
          columns={column}
          // detailPanel={[
          //   {
          //     tooltip: "การดำเนินงานล่าสุด",
          //     render: (rowData) => {
          //       return (
          //         <div className="row">
          //           <Stepper activeStep={1} alternativeLabel>
          //             {/* {steps.map((label) => (
          //               <Step key={label}>
          //                 <StepLabel>{label}</StepLabel>
          //               </Step>
          //             ))} */}
          //           </Stepper>
          //           {/* <Tooltip title="View">
          //             <IconButton
          //               color="success"
          //               href={`/tickets-call-` + rowData.ticketId}
          //             >
          //               <MaterialIcons.StickyNote2 />
          //             </IconButton>
          //           </Tooltip> */}
          //         </div>
          //       );
          //     },
          //   }
          // ]}
          localization={{
            pagination: {
              labelRowsSelect: t('tb_perpage'),
              labelDisplayedRows: "{count} " + t('tb_from') + " {from}-{to} " + t('tb_list'),
              firstTooltip: t('tb_prevTop'),
              previousTooltip: t('tb_prevpage'),
              nextTooltip: t('tb_nextpage'),
              lastTooltip: t('tb_lastTip'),
            },
            toolbar: {
              next: t('tb_nextpage'),
              previous: t('tb_prevpage'),
              searchPlaceholder: t('tb_searching'),
              searchTooltip: t('tb_searching'),
            },
            header: {
              actions: t('tb_action'),
            },
            body: {
              emptyDataSourceMessage: t('tb_notdata'),
              filterRow: {
                filterTooltip: t('tb_filter'),
              },
              addTooltip: t('tb_add'),
              editTooltip: t('tb_edit'),
              deleteTooltip: t('tb_delete'),
              editRow: {
                deleteText: t('tb_delete_header'),
                cancelTooltip: t('tb_delete_cancel_btn'),
                saveTooltip: t('tb_delete_save_btn'),
              },
            },
            grouping: {
              placeholder: t('tb_grouping_header'),
            },
          }}
          data={querys.data}
            onChangeRowsPerPage={(pageSize) => {

                setLimitSize2(pageSize);

                ReloadTable(0, pageSize, null,
                    statusQ !== undefined ? statusQ : null,
                    startDates !== undefined ? startDates : null,
                    endDates !== undefined ? endDates : null);

            }}
                  onChangePage={(page, pageSize) => {
                      setLimitSize2(pageSize);
            ReloadTable(page, pageSize, null, 
              statusQ !== undefined ? statusQ : null, 
              startDates !== undefined ? startDates : null, 
              endDates !== undefined ? endDates : null);
          }}
          onSearchChange={(searchText) => {
              ReloadTable(0, limitSize2, searchText,
              statusQ !== undefined ? statusQ : null, 
              startDates !== undefined ? startDates : null, 
              endDates !== undefined ? endDates : null);
          }}
          // onOrderChange={(orderBy, orderDirection) => {
          //   console.log(orderBy, orderDirection);
          //   ReloadTable(0, null, null,
          //     statusQ !== undefined ? statusQ : null,
          //     startDates !== undefined ? startDates : null,
          //     endDates !== undefined ? endDates : null,
          //     orderBy, orderDirection);
          //   console.log(orderBy, orderDirection);
          // }}
        />

        {/* Modal Cancelled Ticket */}
        <bootstrap.Modal
          show={cancleTicket}
          onHide={() => setCancleTicket(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <bootstrap.Modal.Header closeButton>
            <bootstrap.Modal.Title id="contained-modal-title-vcenter">
              {t('modal_cancel_ticket')}
            </bootstrap.Modal.Title>
          </bootstrap.Modal.Header>
          <bootstrap.Modal.Body>
            <div className="flex-column d-flex justify-content-between py-3">
              <p>
                {t('modal_cancel_ticket_title')} <strong>{id}</strong>{" "}
              </p>
              <div className="flex-row d-flex">
                <strong>
                  <p>{t('modal_cancel_title')}: </p>{" "}
                </strong>
                <p>{title}</p>
              </div>
            </div>
          </bootstrap.Modal.Body>
          <bootstrap.Modal.Footer>
            <bootstrap.Button
              className="w-100"
              variant="danger"
              type="button"
              onClick={() => handleDisable(id)}
            >
              {t('modal_cancel_btn')}
            </bootstrap.Button>
          </bootstrap.Modal.Footer>
        </bootstrap.Modal>
      </div>
    </>
  );
};

export default Dtable;
