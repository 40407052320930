import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../images/logo.png";
import * as BootS from "react-bootstrap";
import * as MaterialIcon from "@mui/icons-material";
import { TicketDataService } from "../services/ticket-service";
import { useTranslation } from "react-i18next";
import cookie from 'js-cookie';
import i18next from 'i18next';

const Navbar = () => {

  const languages = [
    {
      code: 'en',
      name: 'English',
      country_code: 'en'
    },
    {
      code: 'th',
      name: 'ภาษาไทย',
      country_code: 'th'
    }
  ]

  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const [options, setOptions] = useState([]);
  const currentLanguageCode = cookie.get('i18next') || 'th';
  const currentLanguage = languages.find(r => r.code === currentLanguageCode);

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    window.location.href = "/ticket-create-" + index;
    setOpen(false);
  };
  
  const optionData = async () => {
    try
    {
      const res = await TicketDataService.optionTicketData();
      if(res.data.status === true){
        setOptions(res.data.data);
        setOpen(true);
      }
    }
    catch(err){}
  }

  useEffect(() => {
    document.body.dir = currentLanguage.dir || 'ltr'
  }, [currentLanguage]);

  useEffect(() => {
    optionData();
  }, []);
  
  return (
    <header id="header" className="fixed-top d-flex align-items-center">
      <div
        className="container d-flex align-items-center justify-content-between"
        style={{ maxWidth: "1320px" }}
      >
        <div className="logo">
          <Link to="/">
            <div className="flex-row d-flex" style={{ justifyAlign: "center" }}>
              <img
                src={Logo}
                style={{ width: "105px" }}
                alt="https://www.skyfrog.net/"
              />
            </div>
          </Link>
        </div>
        <div className="mx-2 d-flex">
        {(open) ? (
          <>
            <BootS.ButtonGroup as={BootS.ButtonGroup} size="sm" style={{height: "40px", fontSize: "18px"}}>
                <Link to={"/ticket-create"}>
                  <BootS.Button style={{borderRadius: "5px 0px 0px 5px", height: "40px", fontSize: "18px"}}>{t('nav_newticket_btn')}</BootS.Button>
                </Link>

                <BootS.DropdownButton as={BootS.ButtonGroup} size="sm" title="">
                {options.map((option, index) => (
                  <BootS.Dropdown.Item key={option.optionsId} as={BootS.Button} eventKey="1" 
                    onClick={(event) => {
                    handleMenuItemClick(event, index + 1);
                  }}>
                    {option.optionDescrip}
                  </BootS.Dropdown.Item>
                ))}
                </BootS.DropdownButton>
              </BootS.ButtonGroup>
          </>
        ) : null}
              <BootS.DropdownButton as={BootS.ButtonGroup} size="sm" id="changlang" variant="light outline-primary" style={{height: "40px"}} title={<MaterialIcon.Public sx={"1vh"} />}>
                <li className="px-3"><span style={{fontSize: "18px"}}>{t('select_language')}</span></li>
                <BootS.Dropdown.Divider />
                {languages.map(({ code, name, country_code }) => (
                  <BootS.Dropdown.Item key={country_code} as={BootS.Button} eventKey="1" onClick={() => {i18next.changeLanguage(code); window.location.href.split("-")[1] === "SKT" && window.location.reload();}} disabled={code === currentLanguageCode}>
                    {name}
                  </BootS.Dropdown.Item>
                ))}
              </BootS.DropdownButton>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
