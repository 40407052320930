import React from "react";
import { useTranslation } from "react-i18next";

const Footer = () => {
    const { t } = useTranslation();
    
    return (
        <footer className="footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <p>
                           {/* {t('footer_tel')} <strong>02-345-6789 </strong>*/}
                            {t('footer_tel')} <strong>062-596-0666 </strong> 
                            {t('footer_email')} <a href="mailto:support@skyfrog.net"><strong>support@skyfrog.net</strong></a>
                        </p>
                    </div>
                    <div className="col-md-6">
                        <div className="footer-right">
                            <p>
                            Copyright © {new Date().getFullYear()} Skyfrog Ticket - All rights reserved.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer;