import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import * as MaterialIcon from "@mui/icons-material";
import * as MaterialUI from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom";
import * as bootstrap from "react-bootstrap";
import _ from "lodash";

import "../../styles/ticket-edit.css";
import "../../styles/ticket.css";
import "../../styles/bootstrap/css/bootstrap.min.css";
import "../../styles/bootstrap-icons/bootstrap-icons.css";
import "../../styles/style.css";
import TicketIndex from "./TicketIndex";
import { TicketDrawer } from "../../components/Tdrawer";
import { TicketDataService } from "../../services/ticket-service";
import { useTranslation } from "react-i18next";

const TicketEdit = () => {
  const { ticketId } = useParams();
  const refFocus = useRef(null);
  const totop = useRef(null);
  const { t } = useTranslation();

  useEffect(() => {
    cancelToken.current = axios.CancelToken.source();
    getTicket();
    //Cancle the request if the component unmounts
    return () => {
      cancelToken.current.cancel();
      //console.log("DEGUG: Unmounting component");
    };
  }, [ticketId]);

  const [ticket, setTicket] = useState({});
  const [loading, setLoading] = useState();
  const cancelToken = React.useRef(null);
  const [feedback, setFeedback] = React.useState(false);
  const [message, setMessage] = useState("");
  const [score, setScore] = useState();
  const [feedtxt, setFeedtxt] = useState();
  const [send, setSend] = useState(false);
  const [uploadFiles, setUploadFiles] = useState([]);
  const [fileLimit, setFileLimit] = useState(false);
  const [snackbars, setSnackbars] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [key, setKey] = useState(false);
  const [openState, setOpenState] = useState(false);
  const navigate = useNavigate();

  const getTicket = async () => {
    try {
      const res = await TicketDataService.getTickdetail(ticketId, {
        cancelToken: cancelToken.current.CancelToken,
      });
      if (res.data.status === true) {
        setLoading(true);
        setTicket(res.data.data);
      }else{
        navigate("/");
      }
    } catch (err) {
      alert(err.message);
    }
  };
  
  
  const MAX_COUNT = 5;
  const handleUploadFile = (files) => {
    const uploaded = [...uploadFiles];
    let limitExceeded = false;
    files.forEach( async (file) => {
      if (uploaded.findIndex((f) => f.some === file.name) === -1) {
        var result = uploadFiles.map((e) => e.name === file.name).find((e) => { return e === true});

        if(!result) uploaded.push(file);
        //alert(file.name);
        // if(file.size <= 20971520){
        //   uploaded.push(file);
        // }else{
        //   alert("ไฟล์ขนาดใหญ่กว่ากำหนด (จำกัดที่ 20MB)");
        // }
        if (uploaded.length === MAX_COUNT) setFileLimit(true);
        if (uploaded.length > MAX_COUNT) {
          alert(t('edit_upload_1') + MAX_COUNT + t('edit_upload_2'));
          setFileLimit(false);
          limitExceeded = true;
          return true;
        }
      }
    });

    if (!limitExceeded) setUploadFiles(uploaded);
  };

  const handleFileEvent = (file) => {
    const selectedFile = Array.prototype.slice.call(file.target.files);
    handleUploadFile(selectedFile);
  };

  const handleRemoveFile = (file) => {
    const uploaded = [...uploadFiles];
    const index = uploaded.findIndex((f) => f.some === file.name);
    
    uploaded.splice(index, 1);
    setUploadFiles(uploaded);
    setFileLimit(false);
  };

  const handleSnackbar = (message) => {
    setSnackbarMessage(message);
    setSnackbars(true);

    setTimeout(() => {
      setSnackbars(false);
    }, 3000);
  };

  //Pad current whatever
  // if(document.activeElement === refFocus.current ){
    
  // }

  // Pad Scroll
  window.onscroll = function() {scrollFunc()};
  const scrollFunc = () => {
    if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
      totop.current.style.display = "block";
    } else {
      totop.current.style.display = "none";
    }
  }

  const handleReffocus = () => {
    refFocus.current.focus();
  };

  const postData = async (e) => {
    const formData = new FormData();

    _.forEach(uploadFiles, (file) => {
      formData.append("files", file);
    });
    formData.append("message", message);
    formData.append("ticketId", ticketId);

    try {
      if (
        (message !== null &&
          message !== "" &&
          message !== " " &&
          message !== undefined) ||
        uploadFiles.length > 0
      ) {
        await TicketDataService.postTickupdat(ticketId, formData)
          .then((res) => {
            if (res.status === 200) {
              setKey((k) => !k);
              setUploadFiles([]);
              setMessage("");
              setFileLimit(false);
              getTicket();
            }
          })
          .catch((err) => {
            alert(err.message);
          });
      }
      // window.location.reload(true);
    } catch (err) {
      console.log(err.message);
    }
  };

  const postFeedback = async (e) => {
    const formData = new FormData();

    formData.append("score", score);
    formData.append("feedback", feedtxt);
    formData.append("ticketId", ticketId);

    try {
      await TicketDataService.postTickfeed(formData);
      setSend(true);
    } catch (err) {}
  };

  if (send) {
    return <TicketIndex />;
  }

  const handleDrawer = () => {
    setOpenState((openState) => !openState)
  }

  return (
    <>
      {/* PadRight Content */}
      {loading ? (
        <>
          <div id="tochat">
            <MaterialUI.Tooltip title={t("reply_bubble")}>
              <MaterialUI.IconButton
                  onClick={handleReffocus}
                  color="success"
                >
                  <MaterialIcon.ReplyAll />
              </MaterialUI.IconButton>
            </MaterialUI.Tooltip>
          </div>
            <div id="totop" ref={totop} style={{display: "none"}}>
              <MaterialUI.Tooltip title={t("totop_bubble")}>
                <MaterialUI.IconButton
                  onClick={() => {
                    window.scrollTo({top: 0, left: 0, behavior: "smooth"})
                  }}
                  color="success"
                >
                  <MaterialIcon.ArrowUpward />
                </MaterialUI.IconButton>
              </MaterialUI.Tooltip>
            </div>

          <aside id="sidebar" className="sidebar">
            <MaterialUI.ClickAwayListener onClickAway={handleDrawer}>
              <TicketDrawer open={openState} onClose={handleDrawer}>
                <div className="pl-3 p-3" style={{ marginTop: "3rem" }}>
                  <label style={{ fontSize: "16px" }}>Customer Info:</label>
                  <MaterialUI.Tooltip title={t('edit_close_btn')}>
                    <MaterialIcon.Close
                      size={30}
                      style={{ right: 25, position: "absolute" }}
                      onClick={handleDrawer}
                      value="close"
                    />
                  </MaterialUI.Tooltip>
                  <div className="col-form-label">
                    <strong style={{ fontSize: "22px" }}>
                      {ticket.whoProblem}
                    </strong>
                  </div>
                  {/* <label style={{ fontSize: "16px" }}>{ticket.whoProblem}</label> */}
                  <div className="col-form-label">
                    {ticket.telContact !== null && (
                      <>
                        <MaterialUI.Typography variant="subtitle2">
                          {t('edit_title_contact')}
                        </MaterialUI.Typography>
                        <div className="d-flex justify-content-between">
                          <label>{ticket.telContact}</label>
                        </div>
                      </>
                    )}
                    <hr />
                  </div>

                  <MaterialUI.Typography variant="subtitle2">
                    {t('edit_title_createby')} {<strong>{ticket.createBy}</strong>}
                  </MaterialUI.Typography>

                <MaterialUI.Typography variant="subtitle2">
                    {t('create_title_type')}: {<strong>{ticket.optionDescription}</strong>}
                </MaterialUI.Typography>

                  <MaterialUI.Typography variant="subtitle2" className="pt-4">
                    {t('edit_title_status')}
                  </MaterialUI.Typography>
                  <select
                    className="w-100 ml-2 mt-2"
                    style={{ borderRadius: 8 }}
                    defaultValue={ticket.status}
                    disabled
                  >
                    {ticket.statusOpt?.map((status, index) => (
                      <option
                        key={status.valueOption}
                        selected={status.isSelected ? "selected" : ""}
                      >
                        {status.descripOption}
                      </option>
                    ))}
                  </select>

                  <MaterialUI.Typography variant="subtitle2" className="pt-4">
                    {t('edit_title_history')}
                  </MaterialUI.Typography>
                  <div className="container" style={{ marginTop: 20 }}>
                    {ticket.timelineEvent?.map((history) => (
                      <div
                        className={`${
                          history.currentActive ? "step step-active" : "step"
                        }`}
                        key={history.no}
                      >
                        <div>
                          <div className="circle">
                            <i className="fa fa-check" />
                          </div>
                        </div>
                        <div>
                          <div className="title" style={{ fontSize: "15px" }}>
                            {history.event}
                          </div>
                          <div className="caption">{history.timeline}</div>
                        </div>
                      </div>
                    ))}
                  </div>
                  <hr />

                  {ticket.ticketUploads?.length !== 0 ? (
                    <>
                      <MaterialUI.Typography variant="subtitle2" className="pt-4">
                        {t('edit_title_allFile')}
                      </MaterialUI.Typography>
                      {ticket.ticketUploads?.map((upl) => (
                        <MaterialUI.Button
                          key={upl.id}
                          className="mt-2"
                          fullWidth
                          color="success"
                          variant="outlined"
                          href={TicketDataService.viewUploadFile(upl.fileName)}
                          target="_blank"
                          size="medium"
                          startIcon={(() => {
                            switch (upl.fileType) {
                              case "image/png":
                                return <MaterialIcon.Image />;
                              case "image/jpeg":
                                return <MaterialIcon.Image />;
                              case "text/plain":
                                return <MaterialIcon.NoteAlt />;
                              case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                                return <MaterialIcon.Article />;
                              case "application/pdf":
                                return <MaterialIcon.PictureAsPdf />;
                              default:
                                return <MaterialIcon.FileOpen />;
                            }
                          })()}
                        >
                          <MaterialUI.Typography
                            variant="body2"
                            style={{
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                              textAlign: "center",
                            }}
                          >
                            {upl.fileName}
                          </MaterialUI.Typography>
                        </MaterialUI.Button>
                      ))}
                    </>
                  ) : (
                    <MaterialUI.Typography
                      sx={{ fontSize: "18px" }}
                      className="pt-4"
                    >
                      <MaterialUI.Tooltip title="Upload File">
                        <MaterialIcon.InfoSharp sx={{ fontSize: "18px" }} />
                      </MaterialUI.Tooltip>{" "}
                      {t('edit_error_file')}
                    </MaterialUI.Typography>
                  )}
                  {/* แนบไฟล์เพิ่มเคิม */}
                  {ticket.status === 4 ? null : (
                    <>
                      <label className="w-100 ticketUpload">
                        <MaterialUI.Tooltip title={t('edit_upload_title')}>
                          <MaterialUI.Button
                            className="mt-2"
                            fullWidth
                            variant="outlined"
                            component="label"
                            size="small"
                            startIcon={<MaterialIcon.FileUpload />}
                          >
                            {t('edit_upload_title')}
                            <input
                              className="uploadFile"
                              id="fileUploads"
                              type={"file"}
                              hidden
                              accept="application/pdf,image/*"
                              onChange={handleFileEvent}
                              disabled={fileLimit}
                              multiple
                            />
                          </MaterialUI.Button>
                        </MaterialUI.Tooltip>
                      </label>
                      <div className="d-flex flex-wrap w-100">
                        {uploadFiles.map((file, index) => (
                          <div className="col-12 d-flex" key={index}>
                            {
                              <MaterialUI.Button
                                className="mt-2"
                                fullWidth
                                color="success"
                                variant="outlined"
                                key={key}
                                href={file.url}
                                target="_blank"
                                textTransform="none"
                                size="medium"
                                accept="application/pdf,image/*"
                                endIcon={
                                  <MaterialUI.Tooltip title={t('edit_upload_delete')}>
                                    <MaterialUI.IconButton>
                                      <MaterialIcon.Close
                                        sx={{ fontSize: "20px" }}
                                        onClick={() => handleRemoveFile(index)}
                                      />
                                    </MaterialUI.IconButton>
                                  </MaterialUI.Tooltip>
                                }
                                startIcon={(() => {
                                  switch (file.type) {
                                    case "image/png":
                                      return <MaterialIcon.Image />;
                                    case "image/jpeg":
                                      return <MaterialIcon.Image />;
                                    case "text/plain":
                                      return <MaterialIcon.NoteAlt />;
                                    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                                      return <MaterialIcon.Article />;
                                    case "application/pdf":
                                      return <MaterialIcon.PictureAsPdf />;
                                    default:
                                      return <MaterialIcon.FileOpen />;
                                  }
                                })()}
                              >
                                <MaterialUI.Typography
                                  variant="body2"
                                  style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    textAlign: "center",
                                  }}
                                >
                                  {file.name.split(".")[0]}
                                </MaterialUI.Typography>
                              </MaterialUI.Button>
                            }
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                  <hr />

                  <MaterialUI.Tooltip title={t('edit_update_btn')}>
                    <MaterialUI.Button
                      className="mt-3"
                      type="button"
                      disabled={
                        message === "" || uploadFiles.length === 0
                          ? message === "" && uploadFiles.length === 0
                          : false
                      }
                      onClick={postData}
                      fullWidth
                      variant="contained"
                      size="small"
                      startIcon={<MaterialIcon.Update />}
                    >
                      {t('edit_update_btn')}
                    </MaterialUI.Button>
                  </MaterialUI.Tooltip>
                </div>
              </TicketDrawer>
            </MaterialUI.ClickAwayListener>
            <div className="py-2">
              <label style={{ fontSize: "16px" }}>Customer Info:</label>
              <div className="col-form-label">
                <strong style={{ fontSize: "22px" }}>
                  {ticket.whoProblem}
                </strong>
              </div>
              {/* <label style={{ fontSize: "16px" }}>{ticket.whoProblem}</label> */}
              <div className="col-form-label">
                {ticket.telContact !== null && (
                  <>
                    <MaterialUI.Typography variant="subtitle2">
                      Contact:
                    </MaterialUI.Typography>
                    <div className="d-flex justify-content-between">
                      <label>{ticket.telContact}</label>
                    </div>
                  </>
                )}
                <hr />
              </div>

              <MaterialUI.Typography variant="subtitle2">
                {t('edit_title_createby')} {<strong>{ticket.createBy}</strong>}
              </MaterialUI.Typography>
                <MaterialUI.Typography variant="subtitle2">
                    {t('create_title_type')}: {<strong>{ticket.optionDescription}</strong>}
                </MaterialUI.Typography>
              <MaterialUI.Typography variant="subtitle2" className="pt-4">
                {t('edit_title_status')}
              </MaterialUI.Typography>
              <select
                className="w-100 ml-2 mt-2"
                style={{ borderRadius: 8 }}
                //defaultValue={ticket.status}
                disabled
              >
                {ticket.statusOpt?.map((status, index) => (
                  <option
                    key={status.valueOption}
                    selected={status.isSelected === true ? "selected" : null}
                  >
                    {status.descripOption}
                  </option>
                ))}
              </select>

              <MaterialUI.Typography variant="subtitle2" className="pt-4">
                {t('edit_title_history')}
              </MaterialUI.Typography>
              <div className="container" style={{ marginTop: 20 }}>
                {ticket.timelineEvent?.map((history) => (
                  <div
                    className={`${
                      history.currentActive ? "step step-active" : "step"
                    }`}
                    key={history.no}
                  >
                    <div>
                      <div className="circle">
                        <i className="fa fa-check" />
                      </div>
                    </div>
                    <div>
                      <div className="title" style={{ fontSize: "15px" }}>
                        {history.event}
                      </div>
                      <div className="caption">{history.timeline}</div>
                    </div>
                  </div>
                ))}
              </div>
              <hr />

              {ticket.ticketUploads?.length !== 0 ? (
                <>
                  <MaterialUI.Typography variant="subtitle2" className="pt-4">
                    {t('edit_title_allFile')}
                  </MaterialUI.Typography>
                  {ticket.ticketUploads?.map((upl) => (
                    <MaterialUI.Button
                      key={upl.id}
                      className="mt-2"
                      fullWidth
                      color="success"
                      variant="outlined"
                          href={`${process.env.REACT_APP_IMAGE_URL}/${upl.fileName}`}
                      target="_blank"
                      size="medium"
                      startIcon={(() => {
                        switch (upl.fileType) {
                          case "image/png":
                            return <MaterialIcon.Image />;
                          case "image/jpeg":
                            return <MaterialIcon.Image />;
                          case "text/plain":
                            return <MaterialIcon.NoteAlt />;
                          case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                            return <MaterialIcon.Article />;
                          case "application/pdf":
                            return <MaterialIcon.PictureAsPdf />;
                          default:
                            return <MaterialIcon.FileCopy />;
                        }
                      })()}
                    >
                      <MaterialUI.Typography
                        variant="body2"
                        style={{
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          textAlign: "center",
                        }}
                      >
                        {upl.fileName}
                      </MaterialUI.Typography>
                    </MaterialUI.Button>
                  ))}
                </>
              ) : (
                <MaterialUI.Typography
                  sx={{ fontSize: "18px" }}
                  className="pt-4"
                >
                  <MaterialUI.Tooltip title="Upload File">
                    <MaterialIcon.InfoSharp sx={{ fontSize: "18px" }} />
                  </MaterialUI.Tooltip>{" "}
                  {t('edit_error_file')}
                </MaterialUI.Typography>
              )}
              {/* แนบไฟล์เพิ่มเคิม */}
              {ticket.status === 4 ? null : (
                <>
                  <label className="w-100 ticketUpload">
                    <MaterialUI.Tooltip title={t('edit_upload_title')}>
                      <MaterialUI.Button
                        className="mt-2"
                        fullWidth
                        variant="outlined"
                        component="label"
                        size="small"
                        startIcon={<MaterialIcon.FileUpload />}
                      >
                        {t('edit_upload_title')}
                        <input
                          className="uploadFile"
                          id="fileUploads"
                          type={"file"}
                          hidden
                          accept="application/pdf,image/*"
                          onChange={handleFileEvent}
                          disabled={fileLimit}
                          multiple
                        />
                      </MaterialUI.Button>
                    </MaterialUI.Tooltip>
                  </label>
                  <div className="d-flex flex-wrap w-100">
                    {uploadFiles.map((file, index) => (
                      <div className="col-12 d-flex" key={index}>
                        {
                          <MaterialUI.Button
                            className="mt-2"
                            fullWidth
                            color="success"
                            variant="outlined"
                            key={key}
                            href={file.url}
                            target="_blank"
                            textTransform="none"
                            size="medium"
                            accept="application/pdf,image/*"
                            endIcon={
                              <MaterialUI.Tooltip title="ลบออก">
                                <MaterialUI.IconButton>
                                  <MaterialIcon.Close
                                    sx={{ fontSize: "20px" }}
                                    onClick={() => handleRemoveFile(index)}
                                  />
                                </MaterialUI.IconButton>
                              </MaterialUI.Tooltip>
                            }
                            startIcon={(() => {
                              switch (file.type) {
                                case "image/png":
                                  return <MaterialIcon.Image />;
                                case "image/jpeg":
                                  return <MaterialIcon.Image />;
                                case "text/plain":
                                  return <MaterialIcon.NoteAlt />;
                                case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                                  return <MaterialIcon.Article />;
                                case "application/pdf":
                                  return <MaterialIcon.PictureAsPdf />;
                                default:
                                  return <MaterialIcon.FileCopy />;
                              }
                            })()}
                          >
                            <MaterialUI.Typography
                              variant="body2"
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                textAlign: "center",
                              }}
                            >
                              {file.name.split(".")[0]}
                            </MaterialUI.Typography>
                          </MaterialUI.Button>
                        }
                      </div>
                    ))}
                  </div>
                </>
              )}
              <hr />

              <MaterialUI.Tooltip title="อัพเดทข้อมูลของตั๋ว">
                <MaterialUI.Button
                  className="mt-3"
                  type="button"
                  disabled={
                    message === "" || uploadFiles.length === 0
                      ? message === "" && uploadFiles.length === 0
                      : false
                  }
                  onClick={postData}
                  fullWidth
                  variant="contained"
                  size="small"
                  startIcon={<MaterialIcon.Update />}
                >
                  {t('edit_update_btn')}
                </MaterialUI.Button>
              </MaterialUI.Tooltip>
            </div>
          </aside>

          <main id="main" className="main">
            {/* Start main title */}
            <div className="headSide">
              <div className="toggle-sidebars mb-1">
                <MaterialUI.Button
                  size="medium"
                  color="inherit"
                  edge="start"
                  onClick={() => {
                    setOpenState(!openState);
                  }}
                >
                  <MaterialIcon.Menu />
                </MaterialUI.Button>
              </div>
              <div className="col-sm-7 headSideCol flex-column d-flex justify-content-between">
                <h4>
                  <strong>{ticket.title}</strong>
                </h4>
                <p className="pb-2" style={{ fontSize: "20px" }}>
                  <MaterialUI.Tooltip
                    title={
                      !snackbars
                        ? t('edit_snackbar_copy_1')
                        : t('edit_snackbar_copy_2')
                    }
                  >
                    <span
                      className="text-success"
                      style={{ paddingRight: ".30em" }}
                      onClick={() => {
                        navigator.clipboard.writeText("#" + ticket.ticketId);
                        handleSnackbar(t('edit_snackbar_copy_1'));
                      }}
                    >
                      <MaterialUI.Snackbar
                        open={snackbars}
                        style={{ marginBottom: "25px" }}
                      >
                        <MaterialUI.Alert
                          severity="success"
                          sx={{ width: "100%" }}
                        >
                          {snackbarMessage}
                        </MaterialUI.Alert>
                      </MaterialUI.Snackbar>
                      {!snackbars ? (
                        <MaterialIcon.CopyAll
                          sx={{
                            fontSize: "22px",
                            marginRight: "5px",
                            marginBottom: "1px",
                          }}
                        />
                      ) : (
                        <MaterialIcon.Check
                          sx={{
                            fontSize: "22px",
                            marginRight: "5px",
                            marginBottom: "1px",
                          }}
                        />
                      )}
                      #{ticket.ticketId}
                    </span>
                  </MaterialUI.Tooltip>
                  | {t('edit_title_whencreate')} {`${ticket.createDate.split("T")[0].split("-")[2]}/` + `${ticket.createDate.split("T")[0].split("-")[1]}/` + `${ticket.createDate.split("T")[0].split("-")[0]}`}
                </p>
              </div>

              <div className="col-sm-5 headSideCol flex-column d-flex">
                <div className="agent container-no-padding pb-1">
                  {t('edit_title_agent')}
                  <strong style={{ paddingLeft: "9px" }}>
                    {ticket.agentRoleId == null
                      ? t('edit_wait_agent')
                      : ticket.agentRoleId}
                  </strong>
                </div>

                <div className="container-no-padding pt-1 d-flex">
                  {t('tb_dueDate')}
                  <strong style={{ paddingLeft: "10px" }}>
                    {ticket.dueDate == null
                      ? t('edit_wait_duedate')
                      : ticket.dueDate.split("T")[0]}
                  </strong>
                </div>
              </div>
            </div>

            {/* Start essay for problem box */}
            <div className="navmain border-bottom mainprob col justify-content-start d-flex flex-column">
              <div
                className="row justify-content-between mx-0"
                style={{ fontWeight: "bold", fontSize: "1rem" }}
              >
                {t('edit_content')}
              </div>
              <div
                className="prob"
                id="mainprob"
                style={{ fontSize: "0.875rem" }}
              >
                {ticket.contents}
              </div>
              {/* {ticket.status === 1 && (
                <>
                  <MaterialUI.Tooltip title="อัพเดทข้อมูลของตั๋ว">
                    <MaterialUI.Button
                      className="mt-3"
                      type="button"
                      fullWidth
                      variant="outlined"
                      size="small"
                      startIcon={<MaterialIcon.Edit />}
                    >
                      แก้ไขรายละเอียดปัญหา
                    </MaterialUI.Button>
                  </MaterialUI.Tooltip>
                </>
              )} */}
            </div>

            {/* Start Content Message */}
            <div className="content" id="message">
              <ul>
                {console.log(ticket.msqSelect)}
                {ticket.msqSelect.length !== 0 ? (
                  ticket.msqSelect.map((msq, index) => {
                    return (
                      <div key={index} style={{marginTop: "20px"}}>
                        <div
                          className="row-fulid justify-content-between mx-0 d-flex"
                          style={
                            msq.sender === "Customer"
                              ? {
                                  fontWeight: "bold",
                                  fontSize: ".8rem",
                                  border: "1px solid #e0e0e0",
                                  borderRadius: "10px 10px 0px 0px",
                                  padding: "10px",
                                  backgroundColor: "#f5f5f5",
                                }
                              : {
                                  fontWeight: "bold",
                                  fontSize: ".8rem",
                                  border: "1px solid #e0e0e0",
                                  borderRadius: "10px 10px 0px 0px",
                                  padding: "10px",
                                  color: "#FFF",
                                  backgroundColor: "#44ad3d",
                                }
                          }
                        >
                          <p>{msq.senderName}</p>
                          <p>{msq.timeSendString}</p>
                        </div>

                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            padding: "10px",
                            borderTop: "none",
                            border: "1px solid #ccc",
                            borderRadius: "0px 0px 10px 10px",
                            backgroundColor: "white",
                          }}
                        >
                          {msq.message}
                          {msq.fileUploads !== null &&
                            msq.fileUploads.map((file, index) => {
                              return (
                                <MaterialUI.Button
                                  //key={msq.fileUploads.id}
                                  className="mt-2"
                                  fullWidth
                                  color="success"
                                  variant="outlined"
                                      href={`${process.env.REACT_APP_IMAGE_URL}/${file.fileName}`}
                                  target="_blank"
                                  alignItems="justify-start"
                                  size="medium"
                                  startIcon={(() => {
                                    switch (file.fileType) {
                                      case "image/png":
                                        return <MaterialIcon.Image />;
                                      case "image/jpeg":
                                        return <MaterialIcon.Image />;
                                      case "text/plain":
                                        return <MaterialIcon.NoteAlt />;
                                      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                                        return <MaterialIcon.Article />;
                                      case "application/pdf":
                                        return <MaterialIcon.PictureAsPdf />;
                                      default:
                                        return <MaterialIcon.FileCopy />;
                                    }
                                  })()}
                                >
                                  <MaterialUI.Typography
                                    variant="body2"
                                    style={{
                                      whiteSpace: "nowrap",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      textAlign: "center",
                                    }}
                                  >
                                    {file.fileName}
                                  </MaterialUI.Typography>
                                </MaterialUI.Button>
                              );
                            })}
                        </div>
                      </div>
                    );
                  })
                ) : (<></>)}
              </ul>
            </div>

            {/* Start Footer for Message box and Feedback Point */}
            {ticket.status < 4 || ticket.status === 5 ? (
              <div className="inputmsg text-center p-0 m-0">
                <div
                  className="row-fulid justify-content-between mx-0 d-flex"
                  style={{
                    fontWeight: "bold",
                    fontSize: ".8rem",
                    border: "1px solid #e0e0e0",
                    backgroundColor: "#f5f5f5",
                  }}
                >
                  <p style={{ paddingBlock: "10px", paddingInline: "16px" }}>
                    {t('edit_form_reply')}
                  </p>
                  <MaterialUI.Tooltip title={t('edit_form_tip_reply')}>
                    <MaterialUI.Button
                      type="button"
                      disabled={message === "" ? message === "" : false}
                      onClick={postData}
                      style={{ borderRadius: 0, paddingInline: 30 }}
                      variant="contained"
                      size="small"
                      endIcon={<MaterialIcon.Send />}
                    >
                      {t('edit_form_reply_btn')}
                    </MaterialUI.Button>
                  </MaterialUI.Tooltip>
                  {/* <input type="button" className="btn btn-success" style={{borderRaduis:"0px"}} value="ส่งข้อความ" /> */}
                </div>
                <textarea
                  type="text"
                  name="message"
                  key={key}
                  ref={refFocus}
                  onClickCapture={handleReffocus}
                  onKeyPress={(e) => {
                    e.code === "Enter" && postData();
                  }}
                  id="messageinput"
                  className="message block-input m-0"
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder={t('edit_form_input_reply')}
                  style={{ minHeight: "115px", maxHeight: "115px" }}
                />
              </div>
            ) : ticket.status === 4 && ticket.scoreFeedback === null ? (
              <>
                <div
                  className="container text-center"
                  style={{ backgroundColor: "#f6f4f4" }}
                >
                  <button
                    type="button"
                    className="my-5 btn w-100 btn-block btn-primary"
                    onClick={() => setFeedback(true)}
                  >
                    {t('edit_form_title_feedback')}
                  </button>

                  <bootstrap.Modal
                    show={feedback}
                    onHide={() => setFeedback(false)}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                  >
                    <bootstrap.Modal.Header closeButton>
                      <bootstrap.Modal.Title id="contained-modal-title-vcenter">
                        {t('edit_form_title_feedback')}
                      </bootstrap.Modal.Title>
                    </bootstrap.Modal.Header>
                    <bootstrap.Modal.Body>
                      <div className="flex-column d-flex justify-content-between py-3 px-4">
                        <div
                          className="flex-row d-flex justify-content-center"
                          onChange={(e) => setScore(e.target.value)}
                        >
                          <div className="item">
                            <label htmlFor={0} style={{ textAlign: "center" }}>
                              <input
                                className="radio"
                                type="radio"
                                name="feedback"
                                id={0}
                                defaultValue={0}
                              />
                              <span>🤬</span>
                              <label>{t('edit_form_feedback_1')}</label>
                            </label>
                          </div>
                          <div className="item mx-5">
                            <label htmlFor={1} style={{ textAlign: "center" }}>
                              <input
                                className="radio"
                                type="radio"
                                name="feedback"
                                id={1}
                                defaultValue={1}
                              />
                              <span>😶</span>
                              <label>{t('edit_form_feedback_2')}</label>
                            </label>
                          </div>
                          <div className="item">
                            <label htmlFor={2} style={{ textAlign: "center" }}>
                              <input
                                className="radio"
                                type="radio"
                                name="feedback"
                                id={2}
                                defaultValue={2}
                              />
                              <span>😍</span>
                              <label>{t('edit_form_feedback_3')}</label>
                            </label>
                          </div>
                          <hr />
                        </div>
                        <textarea
                          className="form-control mt-5"
                          style={{ resize: "none" }}
                          onChange={(e) => setFeedtxt(e.target.value)}
                          rows="3"
                          placeholder={t('edit_form_feedback_input')}
                        />
                      </div>
                    </bootstrap.Modal.Body>
                    <bootstrap.Modal.Footer>
                      <bootstrap.Button
                        className="w-100"
                        variant="secondary"
                        type="button"
                        onClick={postFeedback}
                      >
                        {t('edit_form_feedback_btn')}
                      </bootstrap.Button>
                    </bootstrap.Modal.Footer>
                  </bootstrap.Modal>
                </div>
                <div className="inputmsg text-center p-0 m-0">
                  <div
                    className="row-fulid justify-content-between mx-0 d-flex"
                    style={{
                      fontWeight: "bold",
                      fontSize: ".8rem",
                      border: "1px solid #e0e0e0",
                      backgroundColor: "#f5f5f5",
                    }}
                  >
                    <p style={{ paddingBlock: "10px", paddingInline: "16px" }}>
                      {t('edit_form_reply')}
                    </p>
                    <MaterialUI.Tooltip title={t('edit_form_tip_reply')}>
                      <MaterialUI.Button
                        type="button"
                        disabled={message === "" ? message === "" : false}
                        onClick={postData}
                        style={{ borderRadius: 0, paddingInline: 30 }}
                        variant="contained"
                        size="small"
                        endIcon={<MaterialIcon.Send />}
                      >
                        {t('edit_form_reply_btn')}
                      </MaterialUI.Button>
                    </MaterialUI.Tooltip>
                    {/* <input type="button" className="btn btn-success" style={{borderRaduis:"0px"}} value="ส่งข้อความ" /> */}
                  </div>
                  <textarea
                    type="text"
                    name="message"
                    key={key}
                    ref={refFocus}
                    onClickCapture={handleReffocus}
                    onKeyPress={(e) => {
                      e.code === "Enter" && postData();
                    }}
                    id="message"
                    className="message block-input m-0"
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder={t('edit_form_input_reply')}
                    style={{ minHeight: "115px", maxHeight: "115px" }}
                  />
                </div>
              </>
            ) : (
              <>
                <hr className="m-0 p-0" />
                <div className="text-center my-4">
                  <MaterialIcon.CheckCircle
                    className="mb-2"
                    color="primary"
                    sx={{ fontSize: "3rem" }}
                  />
                  <br />
                  <p>
                    <strong
                      className="text-center"
                      style={{ fontSize: "2.5vh" }}
                    >
                      {t('edit_form_feedback_title')}<br></br>
                      {t('edit_form_feedback_subtitle')}
                    </strong>
                  </p>
                </div>
                <div className="inputmsg text-center p-0 m-0">
                  <div
                    className="row-fulid justify-content-between mx-0 d-flex"
                    style={{
                      fontWeight: "bold",
                      fontSize: ".8rem",
                      border: "1px solid #e0e0e0",
                      backgroundColor: "#f5f5f5",
                    }}
                  >
                    <p style={{ paddingBlock: "10px", paddingInline: "16px" }}>
                      {t('edit_form_reply')}
                    </p>
                    <MaterialUI.Tooltip title={t('edit_form_tip_reply')}>
                      <MaterialUI.Button
                        type="button"
                        disabled={message === "" ? message === "" : false}
                        onClick={postData}
                        style={{ borderRadius: 0, paddingInline: 30 }}
                        variant="contained"
                        size="small"
                        endIcon={<MaterialIcon.Send />}
                      >
                        {t('edit_form_reply_btn')}
                      </MaterialUI.Button>
                    </MaterialUI.Tooltip>
                    {/* <input type="button" className="btn btn-success" style={{borderRaduis:"0px"}} value="ส่งข้อความ" /> */}
                  </div>
                  <textarea
                    type="text"
                    name="message"
                    key={key}
                    ref={refFocus}
                    onClickCapture={handleReffocus}
                    onKeyPress={(e) => {
                      e.code === "Enter" && postData();
                    }}
                    id="message"
                    className="message block-input m-0"
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder={t('edit_form_input_reply')}
                    style={{ minHeight: "115px", maxHeight: "115px" }}
                  />
                </div>
              </>
            )}
          </main>
        </>
      ) : (
        <>
        </>
      )}
    </>
  );
};

export default TicketEdit;
