import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from "react-router-dom";
import App from './App';
import reportWebVitals from './reportWebVitals';
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import LangTh from './locales/th/translation.json';
import LangEn from './locales/en/translation.json';

import './index.css';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(Backend)
  .init({
    fallbackLng: 'th',
    resources: {
      en: {
        translation: LangEn
      },
      th: {
        translation: LangTh
      }
    },
    detection: {
      order: ['cookie', 'htmlTag', 'localStorage', 'path', 'subdomain'],
      caches: ['cookie'],
    },
    // backend: {
    //   loadPath: '/locales/{{lng}}/translation.json'
    // },
    // react: { useSuspense: false},
  });

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <BrowserRouter basename="/ticket">
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
