import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

import { TicketDataService } from "../../services/ticket-service";

const ValidityState = () => {
  
    const { token } = useParams();
    const tokenState = async () => {
      const resp = await TicketDataService.authenState(token);

      try{
        if(resp.data.status){
          const valideToken = await resp.data.token
          const dateNow = new Date();
          dateNow.setTime(dateNow.setMinutes(dateNow.getMinutes() + 20));
          let expires = `expires=${dateNow.toUTCString()};`
          document.cookie = `skf=${valideToken}; path=/; ${expires}`
          return window.location.href = "/"
        }else{
          console.log("Faild:: ", resp.data.message);
          return window.location.href = "/";
        }
      }catch(error){
        console.log(error);
        return window.location.href = "/";
      }
    }
    useEffect(() => {
      tokenState();
    }, []);
    
  
    return (
      <></>
    );
  }

  export default ValidityState;