import React, { useState, useEffect } from "react";
import { Avatar } from "@mui/material";
import Logo from "../../images/logo.png";
import * as MaterialIcons from "@mui/icons-material";
import * as MaterialUI from "@mui/material";

import "../../styles/bootstrap/css/bootstrap.min.css";
import "../../styles/bootstrap-icons/bootstrap-icons.css";
import "../../styles/style.css";
import Slipballon from "../../components/Slipballon";
import Dtable from "../../components/Dtable";
import { TicketDataService } from "../../services/ticket-service";
import { useTranslation } from "react-i18next";

const TicketIndex = () => {
  const { t } = useTranslation();
  const [count, setCount] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorOpen, setErrorOpen] = useState(true);
  const [errorPages, setErrorPages] = useState(false);
  const [loadingStyle, setLoadingStyle] = useState({ width: '100%', height: '100%' })

  const current = new Date();
  const date = `${current.getHours()}`;
  const time = date < 12 ? t('good_morning') : date < 17 ? t('good_afternoon') : date < 19 ? t('good_evening') : date < 24 ? t('good_night') : t('good_beforemorning');
  const getTicket = async () => {
    try {
      const resC = await TicketDataService.getTickcout();
      if(resC.data.status === false)
      {
        setLoading(false);
      }
      else
      {
        setCount(resC.data.data);
        setLoading(true);
      }

    } catch (err) {
      setLoading(false);
    };
  };

  const errorPage = () => {
    setErrorPages(true);
    setTimeout(() => {
      setErrorOpen(false);
    }, 3000);
  }

  useEffect(() => {
    getTicket();
    if (errorOpen) {
      setTimeout(() => {
        setLoadingStyle({ width: '100%', height: '100%' ,display: "none" });
        errorPage();
      }, 5000);
    }
  }, []);

  return (
    <div style={{marginTop: "3rem"}}>
      {loading ? (
        <div className="ticketLists">
          <section id="hero" className="col-md-3">
            <div
              className="hero-container aos-init aos-animate"
              data-aos="fade-up"
            >
              <h1>{t("header_welcome")}</h1>
              <h2>{t("subheader_welcome")}</h2>
              <div
                className="whor d-flex px-4 py-3"
                style={{
                  backgroundColor: "white",
                  borderRadius: "15px",
                  textAlign: "left",
                }}
              >
                <Avatar
                  className="me-3"
                  sx={{ height: "55px", width: "55px" }}
                />
                {/* <div className="me-3 text-center" style={{
                  height: "50%", 
                  width: "50%", 
                  borderRadius: "50%", 
                  backgroundColor: "black",
                  alignContent: "center",
                  color: "white",
                  justifyContent: "center",
                  fontSize: "30px"
                }}>
                  <p>{count.user.charAt(0)}</p>
                </div> */}
                <div className="flex-column">
                  <p>{time}, {count.user}</p>
                  <h6 className="p-0 m-0 pb-1">
                    <strong>{count.customerName}</strong>
                  </h6>
                </div>
              </div>
            </div>
          </section>
          <main id="main" className="">
            <section className="about">
              <div className="container px-0">
                <div className="row justify-content-center px-2">
                  <div className="col-lg-11">
                    <div className="row justify-content-center">
                      <>
                        <Slipballon
                          materialIcons={
                            <MaterialIcons.FileOpen
                              fontSize="large"
                            />
                          }
                          count={count.issueCreate}
                          label={t('issue_create')}
                        />

                        <Slipballon
                          materialIcons={
                            <MaterialIcons.PendingActions
                              fontSize="large"
                            />
                          }
                          count={count.issuePending}
                          label={t('issue_pending')}
                        />

                        <Slipballon
                          materialIcons={
                            <MaterialIcons.MonitorHeart
                              fontSize="large"
                            />
                          }
                          count={count.issueMonitoring}
                          label={t('issue_monitoring')}
                        />

                        <Slipballon
                          materialIcons={
                            <MaterialIcons.AssignmentTurnedIn
                            fontSize="large"
                            />
                          }
                          count={count.issueClosed}
                          label={t('issue_closed')}
                        />
                      </>
                    </div>
                  </div>
                </div>
                <div className="container p-0">
                  <Dtable />
                </div>
              </div>
            </section>
          </main>
        </div>
      ) : (
        <>
        <MaterialUI.Box className="text-center" sx={loadingStyle} style={{paddingBlock: "15%", paddingInline: "35%"}}>
              <img
                src={Logo}
                className="py-3"
                style={{ width: "105px", marginLeft: "-15px" }}
                alt="https://www.skyfrog.net/"
              />
            <h6 style={{fontSize: "15px"}}><strong>{t('loading')}</strong></h6>
            <MaterialUI.LinearProgress />
          </MaterialUI.Box>

        {errorPages && (
          <>
            <MaterialUI.Collapse in={errorOpen}>
              <MaterialUI.Alert 
                onClose={() => {setErrorOpen(false)}}
                severity="error"
              >
                {t('error_connection')}
              </MaterialUI.Alert>
            </MaterialUI.Collapse>
            <div className="text-center" style={{paddingTop: "6.5rem"}}>
              <MaterialIcons.Error sx={{ fontSize: 85 }} />
              <h5 className="pt-3">
                {t('solution_error_connection')}
              </h5>
              <div className="border-top mt-5 pt-3">
                <h6>{t('subsolution_error_connection')}</h6>
              </div>
            </div>
          </>)}
        </>
      )}
    </div>
  );
};

export default TicketIndex;
