import * as React from "react";

const Slipballon = (props) => {
  return (
    <>
      <div className="col-lg-3 col-md-5 col-6 d-md-flex align-items-md-stretch">
        <div className="count-box py-5">
          <i className="bi">
            {props.materialIcons}
          </i>
          <span
            data-purecounter-start={0}
            data-purecounter-end={65}
            className="purecounter"
            data-purecounter-duration={0}
          >
            {props.count}
          </span>
          <p style={{fontSize: "16px"}}>{props.label}</p>
        </div>
      </div>
    </>
  );
};

export default Slipballon;
